<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Arc Flash Hazards Analysis" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="padding: 0px; background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/arcflash-min.jpg"
              width="1151"
              height="647"
              alt="arc flash analysis"
              title="arcflash"
              class="img-responsive wp-image-12759"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <Paragraph
          ><p>
            An arc flash is a type of electrical explosion that results from a
            low impedance connection to ground or another voltage phase in an
            electrical system. This hazard exists whenever personnel are
            servicing electrical equipment while its still in its energized
            state. The risk of serious and fatal injury from arc flash incidents
            is high, therefore, OSHA requires employers to protect employees
            from these type of electrical hazards. NFPA 70E, the consensus
            standard for electrical safety in the workplace, is the bridge
            between OSHA regulations and compliance.
          </p>
          <p>
            Safety Management Services, Inc. (SMS) provides arc flash services
            to ensure compliance with NEC, NFPA 70E, IEEE 158,4 and OSHA
            requirements. The arc flash hazards analysis (also known as: arc
            flash risk assessment, incident energy analysis, arc flash hazard
            assessment, or arc flash study) is conducted at your facility by one
            of our experts, qualified to evaluate your electrical system. The
            studies can be customized to include fault current and coordination
            analysis, personal protective equipment (PPE) level recommendations,
            arc flash equipment labeling, and more according to your specific
            situation. Recommendations are given for areas presenting risk to
            personnel or equipment.
          </p>
          <p>
            The objective of an arc flash analysis is to minimize or mitigate
            the hazard to your employees and electrical service personnel.
            Consequently, an arc flash hazard analysis will identify the arc
            flash protection boundary, which is the closest approach distance
            allowed before personal protective equipment (PPE) must be worn.
            SMS&nbsp;can provide you with a customized and thorough&nbsp;safety
            solution for your facility,
            <router-link to="/contact" style="color:#aa222c;"
              >contact us</router-link
            >
            for a quote.
          </p>
        </Paragraph>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <Paragraph
          class="col content-box-wrapper content-wrapper link-area-link-icon icon-hover-animation-fade"
          style="background-color:rgba(255,255,255,0);"
          data-animationoffset="100%"
          ><div class="heading heading-with-icon icon-left">
            <h2
              class="mb-4"
              style="font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              Data Collection
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
          <div class="content-container" style="color:#747474;">
            <ul>
              <li>Gathering of electrical prints and one-line diagrams</li>
              <li>Compilation of conductor sizes and lengths</li>
              <li>
                Compilation&nbsp;of distribution equipment types and ratings
              </li>
              <li>Compliation of overcurrent protection device information</li>
              <li>Gathering&nbsp;of transformer ratings and impedances</li>
              <li>Review energy levels with electrical utility</li>
            </ul>
          </div></Paragraph
        >
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <Paragraph
          class="col content-box-wrapper content-wrapper link-area-link-icon icon-hover-animation-fade"
          style="background-color:rgba(255,255,255,0);"
          data-animationoffset="100%"
          ><div class="heading heading-with-icon icon-left">
            <h2
              class="mb-4"
              style="font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              Engineering Analysis
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
          <div class="content-container" style="color:#747474;">
            <ul>
              <li>Collating and analyzing the data collection</li>
              <li>Cross checking of information to electrical systems</li>
              <li>Fault analysis information formation</li>
              <li>Arc flash information formation</li>
              <li>One-line diagram creation</li>
            </ul>
          </div></Paragraph
        >
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pl-2">
        <Paragraph
          class="col content-box-wrapper content-wrapper link-area-link-icon icon-hover-animation-fade"
          style="background-color:rgba(255,255,255,0);"
          data-animationoffset="100%"
          ><div class="heading heading-with-icon icon-left">
            <h2
              class="mb-4"
              style="font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              Warning Labels
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
          <div class="content-container" style="color:#747474;">
            <ul>
              <li>
                Using engineering analysis information to develop labels per
                ANSI Z535.4 specifications
              </li>
              <li>
                Creation of labels on materials unique to your facility’s needs
              </li>
              <li>Deliver labels to your facility for application</li>
            </ul>
          </div></Paragraph
        >
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Arc Flash Analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS performs arc flash services to ensure compliance with NFPA 70E, IEEE 158.4 and identify any areas that require arc flash boundaries."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
